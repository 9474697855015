import React, { useState } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import { translateText, translatePath } from '../config/translation'

const useStyles = makeStyles(() => ({
  formElement: {
    display: 'block',
    maxWidth: '320px',
    margin: '48px auto 0 auto',
    '& p': {
      margin: 0,
    }
  },
  rsvpButton: {
    marginTop: 32,
    padding: '0 42px',
  },
}))

const RSVPFindForm = ({ lang }) => {
  const data = useStaticQuery(graphql`
    query {
      allNodeRsvp {
        edges {
          node {
            title
          }
        }
      }
    }
  `)
  const classes = useStyles()
  const rsvpCodes = data.allNodeRsvp.edges.map(edge => edge.node.title)
  const [rsvpCode, setRsvpCode] = useState('')
  const [rsvpLink, setRsvpLink] = useState(translatePath('/rsvp', lang))
  const [invalidRsvpCode, setInvalidRsvpCode] = useState('')

  const handleRsvpChange = event => {
    const rsvpCodeInput = event.target.value
    setRsvpCode(rsvpCodeInput);
    setRsvpLink(getRsvpLink(rsvpCodeInput))
  }

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      handleSubmit()
    }
    else if (event.keyCode === 27) {
      setRsvpCode('')
    }
  }

  const handleSubmit = () => {
    if (rsvpLink !== 'not-found') {
      navigate(rsvpLink)
    } else {
      setInvalidRsvpCode(rsvpCode)
      setRsvpCode('')
    }
  }

  const getRsvpLink = (rsvpCodeInput) => {
    if (rsvpCodes.indexOf(rsvpCodeInput) > -1) {
      return (lang === 'pt') ? `/pt/rsvp/${rsvpCodeInput}` : `/rsvp/${rsvpCodeInput}`
    }
    else {
      return `not-found`
    }
  }

  const description = (lang === 'pt') ? <p>Pedimos que confirme sua presença <strong>até 1&nbsp;de&nbsp;setembro&nbsp;de&nbsp;2019</strong> para que possamos nos preparar adequadamente.</p> : <p>We would greatly appreciate if you could <strong>RSVP us no later than September&nbsp;1,&nbsp;2019</strong> so we can prepare accordingly.</p>
  
  let instruction = ''
  if (invalidRsvpCode.length !== 0) {
    instruction = (lang === 'pt') ? <p>O convite <strong>{invalidRsvpCode}</strong> não foi encontrado.<br/ > Por favor insira novamente seu código de convite.</p> : <p>The invitation <strong>{invalidRsvpCode}</strong> wasn't found.<br/ > Please re-enter your invitation code.</p>
  } else {
    instruction = (lang === 'pt') ? <p>Por favor insira seu código de convite</p> : <p>Please enter your invitation code.</p>
  }

  return (
    <Box>
      { description }
      <FormControl className={classes.formElement}>
        { instruction }
        <TextField id="name" label={ translateText('Invitation code', lang) } value={ rsvpCode } onChange={ handleRsvpChange } onKeyDown={handleKeyDown} required={ true } margin='normal' variant="outlined" fullWidth error={ invalidRsvpCode.length !== 0 } />
      </FormControl>
      <Button className={ classes.rsvpButton } onClick={ handleSubmit }>
        { (lang === 'pt') ? 'ENVIAR' : 'RSVP' }
      </Button>
    </Box>
  )
}

export default RSVPFindForm