import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'
import Img from "gatsby-image"
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 64,
    maxWidth: 1400,
    margin: '0 auto',
  },
}))

const Footer = ({ siteTitle }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      philly: file(relativePath: { eq: "images/philly2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Box className={classes.root}>
      <Img fluid={data.philly.childImageSharp.fluid} />
    </Box>
  )
}

export default Footer
