import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from "gatsby"
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { translateMenuItem, translatePath } from '../config/translation'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuToggle: {
    width: '100%',
    padding: 16,
    cursor: 'pointer',
  },
  menuList: {
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    borderBottom: '2px solid black',
    transition: 'max-height .4s ease',
  },
  menuListClosed: {
    maxHeight: 0,
  },
  menuListOpen: {
    maxHeight: '560px',
  },
  menuListItem: {

  },
  menuLink: {
    display: 'block',
    padding: 16,
    '& span': {
      padding: 4,
      display: 'inline-block',
    },
  },
  menuLinkActive: {
    '& span': {
      borderBottom: '2px solid black',
    },
  },
}))

const MobileMenu = ({activeRoute, lang}) => {
  const classes = useStyles()
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleClick = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  const menuListClass = menuIsOpen? `${classes.menuList} ${classes.menuListOpen}` : `${classes.menuList} ${classes.menuListClosed}`

  return (
    <Box className={classes.root}>
      <Container>
        <Box className={classes.menuToggle} onClick={handleClick}>MENU</Box>
        <ul className={menuListClass}>
          <li className={classes.menuListItem} style={{borderTop: '2px solid black'}}>
            <Link to={ translatePath('/', lang) } className={(activeRoute === translatePath('/', lang)) ? `${classes.menuLink} ${classes.menuLinkActive}` : classes.menuLink}>
              <span>KELLY & HELIO</span>
            </Link>
          </li>
          <li className={classes.menuListItem}>
            <Link to={ translatePath('/wedding', lang) } className={(activeRoute === translatePath('/wedding', lang)) ? `${classes.menuLink} ${classes.menuLinkActive}` : classes.menuLink}>
              <span>{ translateMenuItem('WEDDING', lang) }</span>
            </Link>
          </li>
          <li className={classes.menuListItem}>
            <Link to={ translatePath('/our-story', lang) } className={(activeRoute === translatePath('/our-story', lang)) ? `${classes.menuLink} ${classes.menuLinkActive}` : classes.menuLink}>
              <span>{ translateMenuItem('OUR STORY', lang) }</span>
            </Link>
          </li>
          <li className={classes.menuListItem}>
            <Link to={ translatePath('/travel', lang) } className={(activeRoute === translatePath('/travel', lang)) ? `${classes.menuLink} ${classes.menuLinkActive}` : classes.menuLink}>
              <span>{ translateMenuItem('TRAVEL', lang) }</span>
            </Link>
          </li>
          <li className={classes.menuListItem}>
            <Link to={ translatePath('/registry', lang) } className={(activeRoute === translatePath('/registry', lang)) ? `${classes.menuLink} ${classes.menuLinkActive}` : classes.menuLink}>
              <span>{ translateMenuItem('REGISTRY', lang) }</span>
            </Link>
          </li>
          <li className={classes.menuListItem}>
            <Link to={ translatePath('/rsvp', lang) } className={(activeRoute === translatePath('/rsvp', lang)) ? `${classes.menuLink} ${classes.menuLinkActive}` : classes.menuLink}>
              <span>{ translateMenuItem('RSVP', lang) }</span>
            </Link>
          </li>
        </ul>
      </Container>
    </Box>
  )
}

export default MobileMenu
