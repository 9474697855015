import React, { useState } from "react"
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import LanguageIcon from '@material-ui/icons/Language'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import FlagBrasil from './flagBrasil'
import FlagUSA from './flagUSA'
import { translatePath, translateText } from '../config/translation'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 12,
    right: 0,
  },
  languageIconWrapper: {
    position: 'absolute',
    top: 12,
    right: 24,
    display: 'flex',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      top: '-6px',
      '& svg': {
        height: 18,
      },
    },
  },
  hideSuperSmallScreen: {
    [theme.breakpoints.down(360)]: {
      display: 'none',
    },
  },
  langSelectorWrapper: {
    position: 'absolute',
    top: 36,
    right: 20,
    zIndex: 10,
    overflow: 'hidden',
    transition: 'max-height .2s ease',
    [theme.breakpoints.down('xs')]: {
      top: 16,
    },
  },
  langSelectorOpen: {
    maxHeight: 180,
  },
  langSelectorClose: {
    maxHeight: 0,
  },
  langSelectorPaper: {
    margin: 4,
  },
}));

const LanguageSelector = ({ activeRoute, lang }) => {
  const classes = useStyles()

  const [langIsOpen, setLangIsOpen] = useState(false)

  const getLangBoxClassNames = () => {
    if (langIsOpen) {
      return `${classes.langSelectorWrapper} ${classes.langSelectorOpen}`
    }
    else {
      return `${classes.langSelectorWrapper} ${classes.langSelectorClose}`
    }
  }

  const toggleLang = () => {
    setLangIsOpen(!langIsOpen)
  }

  const handleClickAway = () => {
    setLangIsOpen(false)
  }

  const linkEnglish = (lang === 'pt') ? translatePath(activeRoute, 'en', 'pt') : activeRoute
  const linkPortuguese = (lang === 'pt') ? activeRoute : translatePath(activeRoute, 'pt')
  
  const currentFlag = (lang === 'pt') ? <FlagBrasil className={ classes.hideSuperSmallScreen } /> : <FlagUSA className={ classes.hideSuperSmallScreen } />

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={ classes.root }>
        <Box className={ classes.languageIconWrapper } onClick={ toggleLang }>
          <LanguageIcon /> {currentFlag}
        </Box>
        <Box className={ getLangBoxClassNames() }>
          <Paper elevation={2} className={ classes.langSelectorPaper }>
            <List>
              <ListItem button component={Link} to={ linkPortuguese } onClick={ toggleLang } selected={ lang === 'pt' }>
                <ListItemIcon>
                  <FlagBrasil />
                </ListItemIcon>
                <ListItemText primary={ translateText("Portuguese", lang) } />
              </ListItem>
              <ListItem button component={Link} to={ linkEnglish } onClick={ toggleLang } selected={ lang === 'en' }>
                <ListItemIcon>
                  <FlagUSA />
                </ListItemIcon>
                <ListItemText primary={ translateText("English", lang) } />
              </ListItem>
            </List>
          </Paper>
        </Box>
      </Box>
    </ClickAwayListener>
  )
}

export default LanguageSelector