/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'
import { Helmet } from "react-helmet"
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import PageName from "./page-name"
import Header from "./header"
import DesktopMenu from "./desktop-menu"
import MobileMenu from "./mobile-menu"
import Footer from "./footer"
import "./layout.css"

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      text: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
    },
  },
});

const useStyles = makeStyles({
  contentWrapper: {
    marginTop: 32,
    minHeight: '90vh',
  },
  '@global': {
    body: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 16,
      fontWeight: 300,
      lineHeight: 2,
      backgroundColor: 'white',
    },
  },
});

const Layout = ({ children, pageName, activeRoute, lang }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      sitePage {
        id
      }
    }
  `)

  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Montserrat:100,300,500,700&display=swap" rel="stylesheet" />
      </Helmet>
      <MobileMenu activeRoute={activeRoute} lang={lang} />
      <Header activeRoute={activeRoute} siteTitle={data.site.siteMetadata.title} lang={lang} />
      <DesktopMenu activeRoute={activeRoute} lang={ lang } />
      <PageName activeRoute={activeRoute} pageName={pageName} lang={lang} />
      <Box className={classes.contentWrapper}>
        <main>{children}</main>
      </Box>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
