import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import LanguageSelector from './language-selector'
import { translatePageName } from '../config/translation'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 32,
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  h1: {
    fontSize: 32,
    lineHeight: 1,
    textAlign: 'center',
  },
}));

const PageName = ({pageName, activeRoute, lang}) => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <h1 className={classes.h1}>
        { translatePageName(pageName, lang) }
      </h1>
      <LanguageSelector activeRoute={ activeRoute } lang={ lang } />
    </Container>
  )
}

export default PageName
