import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { jsonApiUrl } from '../config/api'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { translateText } from '../config/translation'

const styles = theme => ({
  root: {
    marginTop: 64,
  },
  title: {
    textAlign: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 48,
  },
  guestList: {
    textAlign: 'center',
  },
  guest: {
    display: 'inline-block',
    margin: 16,
  },
  guestName: {
    margin: 0,
  },
  guestStatus: {
    margin: 0,
    fontSize: 12,
    textAlign: 'center',
  },
})

class GuestList extends Component {
  state = {
    error: null,
    guestListStatus: 'loading',
    guests: {},
  }

  componentDidMount() {
    this.setState({ guestListStatus: 'loading' })
    const requests = [
      axios.get(`${jsonApiUrl}/node/guest?page%5Boffset%5D=0&page%5Blimit%5D=50`),
      axios.get(`${jsonApiUrl}/node/guest?page%5Boffset%5D=50&page%5Blimit%5D=50`),
      axios.get(`${jsonApiUrl}/node/guest?page%5Boffset%5D=100&page%5Blimit%5D=50`),
      axios.get(`${jsonApiUrl}/node/guest?page%5Boffset%5D=150&page%5Blimit%5D=50`),
      axios.get(`${jsonApiUrl}/node/guest?page%5Boffset%5D=200&page%5Blimit%5D=50`),
    ]

    Promise.all(requests)
    .then((resps) => {
      let successResponse = true
      const guestsFromAllResps = resps.reduce((result, resp) => {
        if (resp.status === 200) {
          const guestsFromCurrentResp = resp.data.data.reduce(function(result, guest) {
            if (guest.attributes.title.toLowerCase().includes('guest') && guest.attributes.field_is_attending !== 'yes') {
              return result
            }
            else {
              result[guest.id] = {
                id: guest.id,
                title: guest.attributes.title,
                name: guest.attributes.field_name || '',
                email: guest.attributes.field_email || '',
                isAttending: guest.attributes.field_is_attending || '',
                notes: guest.attributes.field_notes || '',
              }
              return result;
            }
          }, {})
          return {...result, ...guestsFromCurrentResp}
        }
        else {
          successResponse = false
          return result
        }
      }, [])

      if (successResponse) {
        this.setState({
          guests: guestsFromAllResps,
          guestListStatus: 'guests_loaded',
        })
      }
      else {
        this.setState({
          guestListStatus: 'loading_failed',
        })
      }

    })
    .catch(error => {
      console.log(error)
      this.setState({
        guestListStatus: 'loading_failed',
      })
    })
  }

  render() {
    const classes = this.props.classes
    const lang = this.props.lang

    let content = ''
    if (this.state.guestListStatus === 'loading_failed') {
      content = ''
    }
    else if (this.state.guestListStatus === 'loading') {
      content = <Box component='span' className={classes.spinner}><CircularProgress /></Box>
    }
    else if (this.state.guestListStatus === 'guests_loaded') {
      const guestIds = Object.keys(this.state.guests)
      const guestList = guestIds.map((guestId) => {
        const currentGuest = this.state.guests[guestId]
        let isAttendingText = ''
        switch (currentGuest.isAttending) {
          case 'yes':
            isAttendingText = translateText('is attending', lang)
            break
          case 'no':
            isAttendingText = translateText('is not attending', lang)
            break
          default:
            isAttendingText = translateText('pending RSVP', lang)
            break
        }
        return (
            <Box key={currentGuest.id} className={ classes.guest }>
              <p className={ classes.guestName }>{currentGuest.name}</p>
              <p className={ classes.guestStatus }>{ isAttendingText }</p>
            </Box>
        )
      })
      content = <Box>
        <h2 className={ classes.title }>{ translateText('Guest List', lang) }</h2>
        <Box className={ classes.guestList }>
          { guestList }
        </Box>
      </Box>
    }

    return (
      <Box className={ classes.root }>
        { content }
      </Box>
    )
  }
}

export default withStyles(styles)(GuestList);