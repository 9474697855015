import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from "gatsby"
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { translateMenuItem, translatePath } from '../config/translation'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 32,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuLink: {
    padding: '16px 8px',
    fontSize: 18,
    '& span': {
      padding: 4,
      display: 'inline-block',
    },
  },
  menuLinkActive: {
    '& span': {
      borderBottom: '1px solid black',
    },
  },
}))

const DesktopMenu = ({activeRoute, lang}) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Container maxWidth='md' className={classes.menu}>
        <Link to={ translatePath('/wedding', lang) } className={(activeRoute === translatePath('/wedding', lang)) ? `${classes.menuLink} ${classes.menuLinkActive}` : classes.menuLink}>
          <span>{ translateMenuItem('WEDDING', lang) }</span>
        </Link>
        <Link to={ translatePath('/our-story', lang) } className={(activeRoute === translatePath('/our-story', lang)) ? `${classes.menuLink} ${classes.menuLinkActive}` : classes.menuLink}>
          <span>{ translateMenuItem('OUR STORY', lang) }</span>
        </Link>
        <Link to={ translatePath('/travel', lang) } className={(activeRoute === translatePath('/travel', lang)) ? `${classes.menuLink} ${classes.menuLinkActive}` : classes.menuLink}>
          <span>{ translateMenuItem('TRAVEL', lang) }</span>
        </Link>
        <Link to={ translatePath('/registry', lang) } className={(activeRoute === translatePath('/registry', lang)) ? `${classes.menuLink} ${classes.menuLinkActive}` : classes.menuLink}>
          <span>{ translateMenuItem('REGISTRY', lang) }</span>
        </Link>
        <Link to={ translatePath('/rsvp', lang) } className={(activeRoute === translatePath('/rsvp', lang)) ? `${classes.menuLink} ${classes.menuLinkActive}` : classes.menuLink}>
          <span>{ translateMenuItem('RSVP', lang) }</span>
        </Link>
      </Container>
    </Box>
  )
}

export default DesktopMenu
