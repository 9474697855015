const menuItems = {
  'WEDDING': 'CASAMENTO',
  'TRAVEL': 'VIAGEM',
  'OUR STORY': 'NOIVOS',
  'REGISTRY': 'PRESENTES',
  'RSVP': 'PRESENÇA',
}

const pageNames = {
  'RSVP': 'Presença',
  'Kelly & Helio':'Kelly & Helio',
  'Our Story':'Nossa história',
  'Registry':'Presentes',
  'Travel':'Viagem',
  'Wedding':'Casamento',
}

const paths = {
  '/': '/pt/home',
  '/wedding': '/pt/wedding',
  '/travel': '/pt/travel',
  '/our-story': '/pt/our-story',
  '/registry': '/pt/registry',
  '/rsvp': '/pt/rsvp',
  '/get-invitation': '/pt/get-invitation',
  '/rsvp-code': '/pt/rsvp/code',
  '/rsvp-name': '/pt/rsvp/name',
}

const texts = {
  'Guest List': 'Lista de Convidados',
  'is attending': 'estará presente',
  'is not attending': 'estará ausente',
  'pending RSVP': 'esperando resposta',
  'Full name': 'Nome completo',
  'Name': 'Nome',
  'Are you attending?': 'Estarei presente',
  'Yes': 'Sim',
  'No': 'Não',
  'Dietary preferences/restrictions': 'Dietas/alergias',
  'Thank you for your RSVP!': 'Obrigado por responder a confirmação de presença!',
  'Invitation code': 'Nome completo',
  'Invitations were sent by email. Please follow the link in your email to RSVP.': 'Os convites foram enviados por email. Por favor, verifique o seu email para confirmar sua presença.',
  "Can't find your invitation?": 'Não encountrou o seu convite?',
}

const textHome = {
  "Kelly and Helio were married in a private ceremony in Southport, ME.": "Kelly e Helio se casaram em uma cerimônia privada em Southport, Maine, EUA.",
  "Please join us for celebratory food, drinks, and dancing at the reception in Philadelphia, PA.": "Junte-se a nós para comemorar a festa de casamento em Filadélfia, Pensilvânia, EUA.",
  "- and -": "- e -",
  "SATURDAY, OCTOBER 12, 2019": "SÁBADO, 12 DE OUTUBRO, 2019",
  "AT 6:00PM": "6:00PM",
}

const textOurStory = {
  "Kelly and Helio met on December 10th, 2016, in Philadelphia. Helio loves Argentinian Tango, and brought Kelly to a milonga for their first date (fortunately for Kelly, a beginner class was held before she got on the dance floor!)." : "Kelly e Helio se conheceram no dia 10 de dezembro de 2016, em Filadélfia. Helio, entusiasta do tango argentino, convidou a Kelly para uma milonga em seu primeiro encontro (felizmente para Kelly, houve uma aula para iniciantes antes da milonga).",
  "A week after their first date, Helio moved to Brattleboro, Vermont, to start a new job as a Software Engineer. Even though Kelly was still living in Philadelphia, their connection was strong enough to bridge the 300 miles between them, with Helio driving to and from Vermont on the weekends, and Kelly flying from Philadelphia to Boston. Over the years, they made many fun memories together and tried to make the most out of every minute they had together, exploring Philadelphia, Vermont, and just about everywhere in between!": "Uma semana após o primeiro encontro, Helio mudou-se para Brattleboro, Vermont, para começar um novo trabalho como engenheiro de software. Apesar de Kelly ainda estar morando em Filadélfia, a conexão entre eles foi forte o suficiente para superar a distância de 500km entre eles, com o Helio dirigindo entre Brattleboro e Filadélfia nos fins de semana, e a Kelly voando da Filadélfia para Boston. Ao longo dos anos, eles criaram muitas lembranças divertidas, sempre tentando tirar o máximo proveito de cada minuto que estiveram juntos, explorando Filadélfia, Brattleboro e praticamente tudo entre as duas cidades.",
  "On August 10th, 2018, while on vacation with Kelly’s family on Southport Island, Maine, Helio proposed to Kelly on “Sunset Rock,” with Kelly’s immediate family in attendance.": "Em 10 de Agosto de 2018, enquanto estavam de férias com a família de Kelly em Southport Island, Maine, Helio propôs a Kelly em casamento na presença da família dela.",
  "In October of 2018, Helio moved to Philadelphia to live with Kelly, where they both now reside.": "Em outubro de 2018, Helio mudou-se para a Filadélfia para morar com a Kelly, onde ambos agora residem.",
  "On October 27th, 2018, Kelly and Helio were legally wed in a private ceremony on Southport Island, Maine. The ceremony was attended by Helio’s parents, Antonio Ha and Suzi Lee, of Sao Paulo, Brazil; Kelly’s parents, Scott and Kimberly Winter of Canton, Massachusetts; and Kelly’s sister, Lauren Winter, of Canton, Massachusetts.": "Em 27 de outubro de 2018, Kelly e Helio casaram-se legalmente em uma cerimônia privada em Southport Island, Maine. A cerimônia contou com a presença dos pais de Helio, Antonio Ha e Suzi Lee, de São Paulo, Brasil; Os pais de Kelly, Scott e Kimberly Winter de Canton, Massachusetts; e a irmã de Kelly, Lauren Winter, de Canton, Massachusetts.",
  "We are so excited to be able to host our family and friends in Philadelphia, a city that is special to us and our story!": "Estamos muito animados em poder hospedar nossa família e amigos em Filadélfia, uma cidade que é especial para nós e para nossa história.",
  "Although these are the highlights of our story, a picture is worth a thousand words. Another one of Helio’s hobbies is photography, and over the years he has captured many of the moments that he and Kelly have shared together, both big and small. We invite you to scroll through the photos below to see some of the highlights of Kelly and Helio’s relationship.": "Embora estes sejam os destaques da nossa história, uma imagem vale mais do que mil palavras. Um dos hobbies de Helio é a fotografia, e ao longo dos anos ele capturou muitos dos momentos que a Kelly e Helio compartilharam juntos, grandes e pequenos. Convidamos você a explorar as fotos abaixo para ver alguns dos destaques do relacionamento de Kelly e Helio."
}

const textRegistry = {
  "We are so excited to celebrate our union with our dearest family and friends, and the gift of your presence is truly the best one! Please do not feel obliged to give us a gift.": "Estamos muito animados em celebrar nossa união com nossa querida família e amigos, e o presente de sua presença é verdadeiramente o melhor!",
  "We have been longtime renters in Philadelphia and are working hard to be able to purchase a home of our own one day. Should you want to contribute, we will lovingly invest any monetary gifts toward our future home.": "Temos sido inquilinos de longa data na Filadélfia e estamos trabalhando duro para poder comprar uma casa própria um dia. Se você quiser contribuir, investiremos carinhosamente presentes monetários em nosso futuro lar.",
  "If you would prefer to purchase a gift for us, we have created a brief registry on ": "Se você preferir comprar um presente para nós, criamos um breve registro na ",
  "Handmade gifts are always appreciated!": "Presentes artesanais são sempre apreciados!",
}

const textRSVP = {
  "Please enter your email.": "Por favor insira o seu email.",
  "The invitation will be emailed to you.": "Você receberá o convite por email."
}

const textTravel = {
  "GETTING TO PHILADELPHIA": "CHEGANDO EM FILADÉLFIA",
  "There are many ways to get to Philadelphia, including driving, taking the Amtrak Northeast Regional Line, or flying into Philadelphia International Airport. Once in downtown Philadelphia, it is easy to get around by rideshare (Uber or Lyft) or on foot.": "Há muitas maneiras de chegar à Filadélfia, incluindo dirigir, pegar a Linha Regional do Nordeste da Amtrak ou voar para o Aeroporto Internacional da Filadélfia. Uma vez no centro de Filadélfia, é fácil se locomover pela rideshare (Uber ou Lyft) ou a pé.",
  "STAYING IN PHILADELPHIA": "HOSPEDAGEM",
  "Kelly and Helio highly recommend Airbnb.com for lodging. There are many excellent, affordable listings available in Philadelphia. We recommend using our wedding map below to help you decide which neighborhood to stay in.": "Kelly e Helio recomendam o Airbnb.com para hospedagem. Há muitos anúncios excelentes e acessíveis disponíveis na Filadélfia. Recomendamos que você use nosso mapa de casamento abaixo para ajudá-lo a decidir em qual bairro ficar.",
  "In addition to Airbnb, there are many hotels available right in the heart of the city that can be booked through the Google Hotels interface, or on travel sites such as Expedia, TripAdvisor, or Kayak.com.": "Além do Airbnb, há muitos hotéis disponíveis no coração da cidade que podem ser reservados através da interface do Google Hotels ou em sites de viagens, como Expedia, TripAdvisor ou Kayak.com.",
  "GETTING TO THE VENUE": "CHEGANDO À FESTA",
  "Although the venue is not in the heart of the city, it is a short car or train ride away.": "Embora o local não seja no coração da cidade, fica a uma curta distância de carro ou trem.",
  "The PSCA is conveniently located directly next to the Upsal Station on the Chestnut Hill West Regional Rail Line. If you choose to travel by train, there is a train that departs Suburban Station (16th and JFK) at 5:20pm and arrives at the venue at 5:45pm. To view the full Chestnut Hill West Line schedule, please check the SEPTA schedule ": "O PSCA está convenientemente localizado ao lado da Estação Upsal na Linha Ferroviária Regional West Chestnut Hill. Se você optar por viajar de trem, há um trem que parte da Estação Suburbana (16º e JFK) às 17h20 e chega ao local às 17h45. Para ver a programação completa da Chestnut Hill West Line, consulte a programação da SEPTA ",
  "If you choose to drive yourself, the PSCA has a large parking lot available to guests at no charge.": "Se você optar por dirigir sozinho, o PSCA tem um grande estacionamento disponível para os hóspedes sem nenhum custo.",
  "Rideshares (Uber or Lyft) are a popular and economical way to travel around the city and to the venue, and can be accessed by downloading the Uber or Lyft app.": "O Rideshares (Uber ou Lyft) é uma maneira popular e econômica de viajar pela cidade e até o local, e pode ser acessado por meio do download do aplicativo Uber ou Lyft.",
  "To see more detailed information on the layout of the venue, train station, and parking lot, please visit our wedding map and select the Wedding Venue layer.": "Para ver informações mais detalhadas sobre o layout do local, da estação de trem e do estacionamento, acesse nosso mapa de casamento e selecione a camada Wedding Venue.",
  "LEAVING THE VENUE": "APÓS A FESTA",
  "We will be providing transportation from the Philadelphia School of Circus Arts to our after-party at Everybody Hits (529 W Girard Ave) at the end of reception, around 11:15pm.": "Estaremos fornecendo transporte da Escola de Artes do Circo da Filadélfia para nossa after-party no Everybody Hits (529 W Girard Ave) no final da recepção, por volta das 23h15.",
  "OUR WEDDING MAP": "MAPA",
  "The below map shows neighborhoods we recommend staying in, as well as our top picks for bars, restaurants, and things to do around Philadelphia. To open in Google Maps, please click here. Additionally, the Wedding Venue layer provides more detail on the venue and transportation hubs in Philadelphia": "O mapa abaixo mostra os bairros que recomendamos ficar, bem como nossas principais escolhas para bares, restaurantes e coisas para fazer ao redor da Filadélfia. Para abrir no Google Maps, por favor clique aqui. Além disso, a camada Wedding Venue fornece mais detalhes sobre os pontos de encontro e centros de transporte na Filadélfia.",
}

const textWedding = {
  "We are excited to host our friends and family at our reception on October 12th, 2019 in Philadelphia! Because we have many friends and family in Helio’s home country of Brazil, we will also be hosting a second wedding reception in Sao Paulo, Brazil, in late 2020. Stay tuned for the details!": "Estamos muito animados para receber nossos amigos e familiares em nossa recepção em 12 de outubro de 2019 na Filadélfia! Como temos muitos amigos e familiares no país natal de Helio no Brasil, também estaremos realizando uma segunda recepção de casamento em São Paulo, Brasil, no final de 2020. Fique ligado nos detalhes!",
  "Our US wedding reception will be held at the Philadelphia School of Circus Arts, a former church that was converted into a circus arts space in 2017. The space lends itself to all sorts of performances; there will certainly be a few circus surprises throughout the evening!": "Nossa recepção de casamento dos EUA será realizada na Escola de Artes do Circo da Filadélfia, uma antiga igreja que foi convertida em um espaço de artes circenses em 2017. O espaço presta-se a todos os tipos de apresentações; certamente haverá algumas surpresas de circo durante a noite!",
  "There is a free parking lot available on-site to all guests. Additionally, the venue is directly next to Upsal Regional Rail Station.": "Há um estacionamento gratuito disponível no local para todos os hóspedes. Além disso, o local fica ao lado da Estação Ferroviária Regional Upsal.",
  "The reception will start with a cocktail hour at 6pm, followed by dinner and dancing.": "A recepção começará com um coquetel às 18h, seguido de jantar e dança.",
  "After the reception, guests are invited to our after-party at Everybody Hits for beer, bites, and batting cages! Transportation will be provided from the Philadelphia School of Circus Arts to Everybody Hits.": "Após a recepção, os hóspedes são convidados para o nosso after-party no Everybody Hits para cerveja, petiscos e gaiolas de batedura! O transporte será fornecido da Escola de Artes do Circo da Filadélfia a Everybody Hits.",
  "FAQ": "Perguntas frequentes",
  "When should I RSVP by?": "Quando devo confirmar presença?",
  "September 1st, 2019": "1 de Setembro de 2019",
  "I have a food allergy or restriction. Can I make a special request?": "Eu tenho uma alergia ou restrição alimentar. Posso fazer um pedido especial?",
  "We will have options for all dietary restrictions. Please let us know in the allergies section of your RSVP.": "Teremos opções para todas as restrições alimentares. Por favor, deixe-nos saber na seção de alergias do seu RSVP.",
  "Can I bring my kids?": "Posso levar meus filhos?",
  "Unless noted on your invitation, we are unable to accommodate additional children at our reception.": "A menos que seja indicado no seu convite, não podemos acomodar crianças adicionais na nossa recepção.",
  "What should I wear?": "O que eu devo vestir?",
  "Short answer: Whatever makes you feel fabulous (except an all-white outfit, of course)!": "Resposta curta: O que faz você se sentir fabuloso (exceto uma roupa toda branca, é claro)!",
  "Long answer: Short dresses, long dresses, cocktail dresses, jumpsuits, casual suits, slacks, button-downs, sports coats, ties or no ties- this is all perfect for our reception.": "Resposta longa: Vestidos curtos, vestidos longos, vestidos de cocktail, macacões, fatos casuais, calças, botões, casacos desportivos, gravatas ou sem gravatas - tudo isto é perfeito para a nossa recepção.",
  "Is there an after party?": "Existe uma festa depois?",
  "Yes! After the reception, guests are invited to party on at Everybody Hits for beer, bites, and a batting cages! We will be providing transportation to Everybody Hits from the venue around 11:15pm.": "Sim! Após a recepção, os hóspedes são convidados a festejar no Everybody Hits para cerveja, petiscos e gaiolas de batedura! Estaremos fornecendo transporte para Everybody Hits do local em torno das 23h15.",
}

export const translateMenuItem = (text, lang) => {
  if (lang === 'pt') {
    return menuItems[text] || text 
  } else {
    return text
  }
}

export const translatePath = (path, targetLang, pathLang = 'en') => {
  let translatedPath = ''
  if (pathLang === 'en' && targetLang === 'pt') {
    if (path.includes('/rsvp')) {
      translatedPath = '/pt'.concat(path)
    } else {
      translatedPath = paths[path] || path
    }
  }
  else if (pathLang === 'pt' && targetLang === 'en') {
    if (path.includes('/pt/rsvp')) {
      translatedPath = path.replace('/pt', '')
    } else {
      translatedPath = Object.keys(paths).find(key => paths[key] === path) || path
    }
  }
  else {
    translatedPath = path
  }
  return translatedPath
}

export const translateText = (text, lang) => {
  const allTexts = { ...texts, ...textOurStory, ...textRegistry, ...textRSVP, ...textTravel, ...textWedding, ...textHome }
  if (lang === 'pt') {
    return allTexts[text] || text 
  } else {
    return text
  }
}

export const translatePageName = (text, lang) => {
  if (lang === 'pt') {
    return pageNames[text] || text 
  } else {
    return text
  }
}

// fix this
export const translateImageCaption = (text, lang) => {
  return text
}