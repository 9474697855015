import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'
import LanguageSelector from './language-selector'
import Container from '@material-ui/core/Container'
import { translatePath } from '../config/translation'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 32,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  h1: {
    textAlign: 'center',
    fontWeight: 100,
    '& a': {
      textDecoration: 'none',
    },
  },
}));

const Header = ({ activeRoute, siteTitle, lang }) => {
  const classes = useStyles()

  return (
    <Container maxWidth="lg" className={ classes.root }>
      <h1 className={ classes.h1 }>
        <Link to={ translatePath('/', lang) }>
          { siteTitle }
        </Link>
      </h1>
      <LanguageSelector activeRoute={ activeRoute } lang={ lang } />
    </Container>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
